import { type ReactNode, useRef } from 'react';
import classNames from 'classnames';

import { useClickOutside } from '@jsmdg/yoshi';
import styles from './FlyoutBase.module.scss';

type FlyoutBaseProps = {
    readonly onRequestClose: () => void;
    readonly onMouseEnter?: () => void;
    readonly onMouseLeave?: () => void;
    readonly children: ReactNode;
    readonly dataTestId: string;
};

const FlyoutBase = ({
    children,
    dataTestId,
    onMouseEnter,
    onMouseLeave,
    onRequestClose,
}: FlyoutBaseProps): JSX.Element => {
    const node = useRef<HTMLDivElement>(null);
    useClickOutside(node, onRequestClose);

    return (
        <div
            className={classNames('position-absolute end-0 d-grid mt-1x', styles.wrapper)}
            ref={node}
            role="none"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            data-testid={dataTestId}
        >
            {children}
        </div>
    );
};

export { FlyoutBase };
