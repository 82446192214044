import { type MouseEvent, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';
import { type HeaderContext } from 'src/client/types';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import {
    ClickEventLabel,
    ClickEventType,
    GA4EventName,
    GA4FeatureCategory,
    trackCustomerAccountClick,
} from '@jsmdg/tracking';
import {
    BallonIcon,
    Button,
    ButtonColor,
    ButtonSize,
    ButtonVariant,
    ChevronIcon,
    GiftWrappingIcon,
    HeartIcon,
    Link,
    LinkVariant,
    Modal,
    ModalWidthOptions,
    ProfileIcon,
    RenderType,
    ShoppingCartIcon,
    TentIcon,
} from '@jsmdg/yoshi';
import { getLoginUrl, getRegisterUrl } from '../../../helper/customerAccountUrlUtils';
import { trackCustomerAccount } from '../../../helper/trackCustomerAccount';
import { FlyoutBase } from '../FlyoutBase/FlyoutBase';
import styles from './LoggedOutCustomerFlyout.module.scss';

type LoggedOutCustomerFlyoutProps = {
    readonly onRequestClose: () => void;
    readonly onMouseEnter: () => void;
    readonly isTablet: boolean;
};

const messages = defineMessages({
    loginButtonLabel: { defaultMessage: 'Einloggen' },
    title: {
        defaultMessage: 'Hast du schon ein {br} Erlebnis-Konto?',
    },
    registerLink: { defaultMessage: 'Jetzt registrieren' },
    a11yCloseText: { defaultMessage: 'Modal schließen' },
    subtitle: { defaultMessage: 'Deine Vorteile:' },
    description1: { defaultMessage: 'Exklusive Rabatte' },
    description2: { defaultMessage: 'Speichern deiner Favoriten' },
    description3: { defaultMessage: 'Schnellerer Bestellvorgang' },
    description4: { defaultMessage: 'Übersicht all deiner Erlebnisse' },
});

const handleLoginClick = async (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const { href } = event.currentTarget;

    trackCustomerAccountClick(ClickEventType.TEXT_CLICK, ClickEventLabel.LOGIN, {
        eventName: GA4EventName.ClickNavigationItem,
        feature_category: GA4FeatureCategory.HeaderNavigation,
        click_element: 'Login Button',
        click_text: 'Einloggen',
        click_url: href,
    });

    window.location.assign(getLoginUrl(href));
};

const handleRegistrationClick = async (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const { href } = event.currentTarget;

    await trackCustomerAccount(
        ClickEventType.TEXT_CLICK,
        ClickEventLabel.REGISTRATION,
        href,
        'Register Link',
        'Jetzt registrieren',
    );

    window.location.assign(getRegisterUrl(href));
};

const LoggedOutCustomerFlyout = ({
    isTablet,
    onMouseEnter,
    onRequestClose,
}: LoggedOutCustomerFlyoutProps): JSX.Element => {
    const intl = useIntl();
    const { isMydays } = useFragmentContext<HeaderContext>();

    const descriptionList = useMemo(
        () => [
            {
                icon: <GiftWrappingIcon size={20} className="mr-1x" />,
                text: intl.formatMessage(messages.description1),
            },
            {
                icon: <HeartIcon size={20} className="mr-1x" />,
                text: intl.formatMessage(messages.description2),
            },
            {
                icon: <ShoppingCartIcon size={20} className="mr-1x" />,
                text: intl.formatMessage(messages.description3),
            },
            {
                icon: isMydays ? (
                    <TentIcon size={20} className="mr-1x" />
                ) : (
                    <BallonIcon size={20} className="mr-1x" />
                ),
                text: intl.formatMessage(messages.description4),
            },
        ],
        [intl, isMydays],
    );

    const content = (
        <>
            <div className={classNames('p-4x p-sm-2x', styles.contentWrapper)}>
                <Button
                    href="/login"
                    variant={ButtonVariant.Contained}
                    as={RenderType.Link}
                    size={ButtonSize.Responsive}
                    color={ButtonColor.Brand}
                    className="text-center my-1x w-100"
                    dataTestId="complete-button"
                    onClick={handleLoginClick}
                >
                    {intl.formatMessage(messages.loginButtonLabel)}
                </Button>
                <Link
                    className="py-1x"
                    href="/login"
                    variant={LinkVariant.Brand}
                    iconLeft={<ChevronIcon className={styles.icon} />}
                    onClick={handleRegistrationClick}
                    internal
                >
                    {intl.formatMessage(messages.registerLink)}
                </Link>
            </div>
            <div className={classNames('p-2x m-2x m-sm-0', styles.descriptionWrapper)}>
                <h3>{intl.formatMessage(messages.subtitle)}</h3>
                {descriptionList.map(({ icon, text }) => (
                    <div
                        key={text}
                        className={classNames(
                            styles.text,
                            'd-flex justify-content-start align-items-center py-0-5x',
                        )}
                    >
                        {icon}
                        {text}
                    </div>
                ))}
            </div>
        </>
    );

    if (isTablet) {
        return (
            <FlyoutBase
                onRequestClose={onRequestClose}
                dataTestId="customer-account-flyout"
                onMouseEnter={onMouseEnter}
                onMouseLeave={onRequestClose}
            >
                {content}
            </FlyoutBase>
        );
    }

    return (
        <Modal
            isOpen
            a11yCloseText={intl.formatMessage(messages.a11yCloseText)}
            onRequestClose={onRequestClose}
            closeButtonClassName="my-2x mr-2x float-end position-relative"
            modalAddendum={classNames('p-0', styles.descriptionWrapper)}
            width={ModalWidthOptions.Wide}
        >
            <div className={classNames(styles.headingWrapper, 'pt-12x pt-sm-0')}>
                <div className="d-flex justify-content-center align-items-center">
                    <span
                        className={classNames(
                            'd-flex justify-content-center align-items-center',
                            styles.profileIcon,
                        )}
                    >
                        <ProfileIcon size={38} />
                    </span>
                </div>
                <h2 className={classNames('text-center my-0 my-sm-2x p-4x pb-0', styles.heading)}>
                    {intl.formatMessage(messages.title, {
                        br: <br />,
                    })}
                </h2>
            </div>
            {content}
        </Modal>
    );
};

export { LoggedOutCustomerFlyout };
export default LoggedOutCustomerFlyout;
